import React, { useState, useEffect, useContext } from "react";
import Logo from "../../../assets/icons/olinlogo.png";
import Input from "../../common/particles.jsx/Input";
import Carousel from "../../common/particles.jsx/Carousel";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";

import {
  forgotPassword,
  resendOTP,
  verifyOTP,
} from "../../../services/authApi";

import { PasswordContext } from "../../context/ForgotPasswordProvider";

const SEND_VERIFICATION_RETRY_DELAY_SECONDS = 180;

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { email, setEmail } = useContext(PasswordContext);
  const [otpCode, setOtpCode] = useState("");
  const [isSubmitOTPSuccess, setIsSubmitOTPSuccess] = useState(false);
  const [isPressed, setIsPressed] = useState(false);
  const [countdownSeconds, setCountdownSeconds] = useState(
    SEND_VERIFICATION_RETRY_DELAY_SECONDS
  );
  const [errorMessage, setErrorMessage] = useState("");

  const { mutate: mutateForgotPassword } = useMutation({
    mutationFn: forgotPassword,
    onSuccess: () => {
      setIsSubmitOTPSuccess(true);
      setIsPressed(true);
    },
    onError: (err) => {
      setErrorMessage(err.id);
    },
  });

  const { mutate: mutateResendOTP } = useMutation({
    mutationFn: resendOTP,
    onSuccess: () => {
      setIsPressed(true);
    },
    onError: (err) => {
      setErrorMessage(err.message.id);
    },
  });

  const { mutate: mutateVerifyOTP } = useMutation({
    mutationFn: verifyOTP,
    onSuccess: () => {
      navigate("/forgot-password/new-password");
    },
    onError: (err) => {
      setErrorMessage(err.message.id);
    },
  });

  useEffect(() => {
    if (!isPressed) return;

    const interval = setInterval(() => {
      setCountdownSeconds((prevCount) => {
        if (prevCount === 0) {
          clearInterval(interval);
          setIsPressed(false);
          setCountdownSeconds(SEND_VERIFICATION_RETRY_DELAY_SECONDS);
          return prevCount;
        }
        return prevCount - 1;
      });

      return () => clearInterval(interval);
    }, 1000);
  }, [isPressed]);

  return (
    <div className="bg-white ">
      <div className="flex flex-col-reverse lg:flex-row justify-center h-screen">
        <div className="order-first md:order-last bg-cover lg:block lg:w-2/3">
          <div className="flex items-center h-full px-20 bg-[#EAF3FE] bg-opacity-40">
            <div className="w-full my-10">
              <Carousel />
            </div>
          </div>
        </div>

        <div className="flex mt-20 w-full max-w-lg px-6 mx-auto lg:w-2/6 ">
          <div className="flex-1">
            <div className="flex flex-col items-center justify-center text-center">
              <img
                src={Logo}
                alt=""
                className="mt-16 lg:my-0 w-[100px] lg:w-[200px]"
              />
              {isSubmitOTPSuccess ? (
                <p className="mt-10">
                  Kode OTP telah dikirimkan kepada Alamat Email {email}
                </p>
              ) : (
                <>
                  <h2 className="text-4xl font-bold text-center  text-black mt-10 lg:mt-28">
                    Lupa password
                  </h2>
                  <p className="mt-3 text-gray-500 ">
                    Silahkan masukan email akun anda untuk mereset password
                    anda.
                  </p>
                </>
              )}
            </div>
            <div className="mt-8">
              {isSubmitOTPSuccess ? (
                <>
                  <div className="w-full">
                    <div className="flex justify-between">
                      <label className="block mb-2 text-sm text-blue-400">
                        Kode OTP
                      </label>
                      {isPressed ? (
                        <p>
                          {new Date(countdownSeconds * 1000)
                            .toISOString()
                            .substring(14, 19)}
                        </p>
                      ) : null}
                    </div>
                    <div className="flex justify-between items-center w-full">
                      <input
                        type="text"
                        name="OTP"
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        maxLength="6"
                        onChange={(e) => {
                          const value = e.target.value.replace(/\D/g, "");
                          setOtpCode(value);
                          setErrorMessage("");
                        }}
                        value={otpCode}
                        className={`block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white rounded-md border-2 ${
                          Boolean(errorMessage)
                            ? "border-red-500 focus:border-red-400 focus:ring-red-400 focus:ring focus:outline-none focus:ring-opacity-40"
                            : "border-gray-200 focus:border-blue-400 focus:ring-blue-400 focus:ring focus:outline-none focus:ring-opacity-40"
                        }`}
                      />
                    </div>
                    <p className="text-sm text-red-500">{errorMessage}</p>
                  </div>
                </>
              ) : (
                <>
                  <Input
                    label="Email Address"
                    type="email"
                    name="email"
                    placeholder="Example@mail.com"
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setErrorMessage("");
                    }}
                  />
                  <p className="text-red-500 mt-[10px]">{errorMessage}</p>
                </>
              )}
              <div className="mt-6">
                {isSubmitOTPSuccess ? (
                  <button
                    className="w-full px-4 py-2 tracking-wide text-white transition-colors transform bg-blue-500 rounded-md focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50 disabled:bg-gray-500"
                    disabled={
                      otpCode === "" ||
                      (otpCode.length !== 4 && otpCode.length !== 6)
                    }
                    type="button"
                    onClick={() => mutateVerifyOTP({ email, code: otpCode })}
                  >
                    Kirim
                  </button>
                ) : (
                  <button
                    className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50 disabled:bg-gray-500"
                    type="button"
                    disabled={email === ""}
                    onClick={() => mutateForgotPassword({ receiver: email })}
                  >
                    Selanjutnya
                  </button>
                )}
              </div>
              <div className="mt-6 flex justify-center">
                {isSubmitOTPSuccess ? (
                  <>
                    <p>Belum mendapatkan kode OTP?</p>
                    <p>&nbsp;</p>
                    <p
                      className={` ${
                        isPressed
                          ? "text-gray-500"
                          : "text-[#FF6B00] hover:cursor-pointer"
                      }`}
                      onClick={() => {
                        if (isPressed) return;
                        mutateResendOTP({ email });
                      }}
                    >
                      Kirim Ulang OTP
                    </p>
                  </>
                ) : (
                  <>
                    <p>Kembali ke</p>
                    <Link to={"/login"} className="text-[#007AF1]">
                      &nbsp;Login
                    </Link>
                  </>
                )}
              </div>

              {/* <div className="relative mt-6 h-px bg-gray-300">
                  <div className="absolute left-0 top-0 flex justify-center w-full -mt-2">
                    <span className="bg-white px-4 text-xs text-gray-500 uppercase">Belum Registrasi?</span>
                  </div>
                </div>
                <div className="mt-6">
                  <button className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-orange-500 rounded-md hover:bg-blue-400 focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50">
                    Daftar
                  </button>
                </div> */}
              <div className="flex flex-col xl:flex-row items-center justify-center gap-2 my-6">
                <p className="font-medium text-center">Pelajari tentang OLIN</p>
                <p className="border-1 text-center p-2 rounded-xl border-orange-500">
                  Unduh Aplikasi
                </p>
              </div>

              {/* <div className="mt-6 text-sm text-center text-gray-400">
                Don&#x27;t have an account yet? <p className="text-blue-500 underline focus:outline-none hover:underline focus:underline hover:underline">Sign up</p>.
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
