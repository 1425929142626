import { useState, useEffect, useContext } from "react";
import { BsEnvelope } from "react-icons/bs";
import { IoLogoWhatsapp } from "react-icons/io";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import changePasswordBackground from "../../../assets/images/metode verifikasi password.png";
import verificationOTP from "../../../assets/images/verifikasi OTP 1.jpg";
import { registrationOtp, verifyOTP } from "../../../services/authApi";
import { VerificationMethodContext } from "../../context/VerificationMethodProvider";

const SEND_VERIFICATION_RETRY_DELAY_SECONDS = 180;

const VerificationMethod = () => {
  const navigate = useNavigate();
  const email = sessionStorage.getItem("email");
  const {
    isVerificationMethodEmail,
    isVerificationMethodPassword,
    isVerificationMethodPhone,
  } = useContext(VerificationMethodContext);

  const [isPressed, setIsPressed] = useState(false);
  const [isSubmitOTPSuccess, setIsSubmitOTPSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { mutate: mutateRegistrationOtp } = useMutation({
    mutationFn: registrationOtp,
    onSuccess: () => {
      setIsPressed(true);
      setIsSubmitOTPSuccess(true);
    },
    onError: (err) => {
      setErrorMessage(err.id);
    },
  });

  const [countdownSeconds, setCountdownSeconds] = useState(
    SEND_VERIFICATION_RETRY_DELAY_SECONDS
  );

  const [otpCode, setOtpCode] = useState("");
  const { mutate: mutateVerifyOTP } = useMutation({
    mutationFn: verifyOTP,
    onSuccess: () => {
      if (isVerificationMethodEmail) navigate("/profile/request-email");
      else if (isVerificationMethodPassword)
        navigate("/profile/request-password");
      else if (isVerificationMethodPhone) navigate("/profile/request-phone");
    },
    onError: (err) => {
      setErrorMessage(err.message.id);
    },
  });

  useEffect(() => {
    if (!isPressed) return;

    const interval = setInterval(() => {
      setCountdownSeconds((prevCount) => {
        if (prevCount === 0) {
          clearInterval(interval);
          setIsPressed(false);
          setCountdownSeconds(SEND_VERIFICATION_RETRY_DELAY_SECONDS);
          return prevCount;
        }
        return prevCount - 1;
      });
      return () => clearInterval(interval);
    }, 1000);
  }, [isPressed]);

  useEffect(() => {
    if (
      !isVerificationMethodEmail &&
      !isVerificationMethodPassword &&
      !isVerificationMethodPhone
    )
      navigate("/profile");
  }, []);

  return (
    <div className="w-full h-full flex items-center justify-center">
      <img
        src={isSubmitOTPSuccess ? verificationOTP : changePasswordBackground}
        className="w-[50%]"
      />
      <div className="flex flex-col w-[50%]">
        {isSubmitOTPSuccess ? (
          <div className="flex flex-col text-center mb-3">
            <p className="font-bold text-[35px]">Masukkan Kode verifikasi</p>
            <p>Tolong masukkan kode verifikasi yang kami kirimkan ke email</p>
            <p>{email}</p>
          </div>
        ) : (
          <p className="font-bold">
            Pilih salah satu metode dibawah ini untuk mendapatkan kode
            verifikasi:
          </p>
        )}
        {isSubmitOTPSuccess ? (
          <>
            <div className="flex justify-between">
              <label className="block mb-2 text-sm text-blue-400 font-bold">
                Kode OTP
              </label>
              {isPressed ? (
                <p>
                  {new Date(countdownSeconds * 1000)
                    .toISOString()
                    .substring(14, 19)}
                </p>
              ) : null}
            </div>
            <div className="flex flex-col justify-between items-center w-full">
              <input
                type="text"
                name="OTP"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                maxLength="4"
                value={otpCode}
                onChange={(e) => setOtpCode(e.target.value)}
                // className={`block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white rounded-md border-2
                // ${
                //   Boolean(errorMessage)
                //     ? "border-red-500 focus:border-red-400 focus:ring-red-400 focus:ring focus:outline-none focus:ring-opacity-40"
                //     : "border-gray-200 focus:border-blue-400 focus:ring-blue-400 focus:ring focus:outline-none focus:ring-opacity-40"
                // }`}
                className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white rounded-md border-2 "
              />
              <p className="text-red-500 text-left w-full">{errorMessage}</p>
              <button
                disabled={otpCode.length < 4}
                className="bg-[#007AF1] p-3 rounded rounded-md text-white mt-3 w-full font-bold disabled:bg-gray-300"
                onClick={() => mutateVerifyOTP({ email, code: otpCode })}
              >
                Kirim
              </button>
              <div className="flex justify-center mt-1">
                <p>Belum mendapatkan kode OTP?</p>
                <p>&nbsp;</p>
                <p
                  className={` ${
                    isPressed
                      ? "text-gray-500"
                      : "text-[#FF6B00] hover:cursor-pointer"
                  }`}
                >
                  Kirim Ulang OTP
                </p>
              </div>
              {/* <div className="flex justify-center mt-1">
                <p>Atau ubah metode penerimaan kode verifikasi</p>
                <p>&nbsp;</p>
                <p
                  className={"text-[#007AF1] hover:cursor-pointer"}
                  onClick={() => {
                    setIsPressed(false);
                    setCountdownSeconds(SEND_VERIFICATION_RETRY_DELAY_SECONDS);
                    setIsSubmitOTPSuccess(false);
                  }}
                >
                  Ubah
                </p>
              </div> */}
            </div>
          </>
        ) : (
          <>
            <div
              className="w-full flex flex-row items-center rounded-lg border border-3 border-black p-3 justify-between my-3 hover:cursor-pointer grid lg:grid-cols-2 hover:cursor-pointer"
              onClick={() => {
                mutateRegistrationOtp({
                  type: "email",
                  receiver: email,
                });
              }}
            >
              <div className="flex items-center">
                <BsEnvelope className="text-[30px]" />
                <p className="font-semibold ml-3">Email</p>
              </div>
              {/* <p>{sessionStorage.getItem("email")}</p> */}
              <p>{email}</p>
            </div>
            <div className="mb-4 flex flex-row items-center rounded-lg border border-3 border-black p-3 justify-between hover:cursor-pointer grid lg:grid-cols-2">
              <div className="flex items-center">
                <IoLogoWhatsapp className="text-[30px]" />
                <p className="font-semibold ml-3">Whatsapp</p>
              </div>
            </div>
            <p className="text-red-500 text-left w-full">{errorMessage}</p>
          </>
        )}
      </div>
    </div>
  );
};

export default VerificationMethod;
