import axios from "axios";
import Cookies from "js-cookie";
import { GeneratePublicToken } from "../utils/GeneratePublicToken";

const token = () => Cookies.get("accessTokenOfficeOlin");

export const checkStatusByEmail = async ({ email }) => {
  const TokenAuth = GeneratePublicToken().Authorization;
  const TokenAuthID = GeneratePublicToken()["Authorization-ID"];

  try {
    const config = {
      headers: {
        Authorization: TokenAuth,
        "Authorization-ID": TokenAuthID,
      },
      url: `${process.env.REACT_APP_API_ACCOUNT}pos/user/exist`,
      method: "POST",
      data: {
        email,
        is_use_otp: false,
      },
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const login = async (values) => {
  const TokenAuth = GeneratePublicToken().Authorization;
  const TokenAuthID = GeneratePublicToken()["Authorization-ID"];

  try {
    const config = {
      headers: {
        Authorization: TokenAuth,
        "Authorization-ID": TokenAuthID,
      },
      url: `${process.env.REACT_APP_API_ACCOUNT}pos/login`,
      method: "POST",
      data: values,
    };
    const res = await axios(config);
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const getUserProfile = async () => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_ACCOUNT}get/profile`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const postSubmitSIAOrSIPA = async ({ nomor, expired, file, type }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_ACCOUNT}pos/account/resubmit?tipe=${type}`,
      method: "POST",
      data: {
        nomor,
        expired,
        file,
      },
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const forgotPassword = async ({ receiver }) => {
  const TokenAuth = GeneratePublicToken().Authorization;
  const TokenAuthID = GeneratePublicToken()["Authorization-ID"];

  try {
    const config = {
      headers: {
        Authorization: TokenAuth,
        "Authorization-ID": TokenAuthID,
      },
      url: `${process.env.REACT_APP_API_ACCOUNT}pos/password/forgot/otp`,
      method: "POST",
      data: {
        type: "email",
        receiver,
      },
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data.message;
  }
};

export const resendOTP = async ({ email }) => {
  const TokenAuth = GeneratePublicToken().Authorization;
  const TokenAuthID = GeneratePublicToken()["Authorization-ID"];

  try {
    const config = {
      headers: {
        Authorization: TokenAuth,
        "Authorization-ID": TokenAuthID,
      },
      url: `${process.env.REACT_APP_API_ACCOUNT}otp/resend`,
      method: "POST",
      data: {
        email,
      },
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const verifyOTP = async ({ email, code }) => {
  const TokenAuth = GeneratePublicToken().Authorization;
  const TokenAuthID = GeneratePublicToken()["Authorization-ID"];

  try {
    const config = {
      headers: {
        Authorization: TokenAuth,
        "Authorization-ID": TokenAuthID,
      },
      url: `${process.env.REACT_APP_API_ACCOUNT}pos/otp/verify`,
      method: "POST",
      data: {
        code,
        email,
        is_need_access: false,
      },
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const registrationOtp = async ({ type, receiver }) => {
  const TokenAuth = GeneratePublicToken().Authorization;
  const TokenAuthID = GeneratePublicToken()["Authorization-ID"];

  try {
    const config = {
      headers: {
        Authorization: TokenAuth,
        "Authorization-ID": TokenAuthID,
      },
      url: `${process.env.REACT_APP_API_ACCOUNT}pos/registration/otp`,
      method: "POST",
      data: {
        type,
        receiver,
      },
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data.message;
  }
};

export const makeNewPassword = async ({ email, new_password }) => {
  const TokenAuth = GeneratePublicToken().Authorization;
  const TokenAuthID = GeneratePublicToken()["Authorization-ID"];

  try {
    const config = {
      headers: {
        Authorization: TokenAuth,
        "Authorization-ID": TokenAuthID,
      },
      url: `${process.env.REACT_APP_API_ACCOUNT}pos/password/forgot`,
      method: "PUT",
      data: {
        email,
        new_password,
      },
    };
    const res = await axios(config);
    return res;
  } catch (err) {
    throw err.response.data;
  }
};

export const changePassword = async ({
  email,
  new_password,
  current_password,
}) => {
  const TokenAuth = GeneratePublicToken().Authorization;
  const TokenAuthID = GeneratePublicToken()["Authorization-ID"];

  try {
    const config = {
      headers: {
        Authorization: TokenAuth,
        "Authorization-ID": TokenAuthID,
      },
      url: `${process.env.REACT_APP_API_ACCOUNT}pos/password/change`,
      method: "PUT",
      data: {
        email,
        new_password,
        current_password,
      },
    };
    const res = await axios(config);
    return res;
  } catch (err) {
    throw err.response.data;
  }
};

export const changeEmail = async (email) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_ACCOUNT}pos/email/change`,
      method: "PUT",
      data: {
        email: email,
      },
    };
    const res = await axios(config);
    return res;
  } catch (err) {
    throw err.response.data;
  }
};

export const getDetailInformation = async () => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_ACCOUNT}get/detail/information`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getDetailInformationOwner = async () => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_ACCOUNT}get/detail/information/user`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const changePhone = async (phone) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_ACCOUNT}pos/phone/change`,
      method: "PUT",
      data: {
        phone: phone,
      },
    };
    const res = await axios(config);
    return res;
  } catch (err) {
    throw err.response.data;
  }
};

export const getDetailSubscription = async () => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_ACCOUNT}subscription`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getSubscription = async (offset, limit) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_ACCOUNT}subscription/default?offset=${offset}&limit=${limit}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const getHistoryTransaction = async (offset, limit) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_ACCOUNT}subscription/history-transaction?offset=${offset}&limit=${limit}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const getDetailCheckout = async (id) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_ACCOUNT}subscription-package?id=${id}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getDetailPayment = async (billno, type) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_ACCOUNT}subscription/check-payment?billno=${billno}&type=${type}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const verifySubscriptionCode = async (code) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_ACCOUNT}subscription/redeem-voucher?voucher=${code}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getSipaAndFullname = async (company_user_id) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_ACCOUNT}get/sipa-and-fullname?company_user_id=${company_user_id}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};
